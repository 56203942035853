import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SEO from '../components/Seo'
import Layout from '../layouts/'
import PageBody from '../components/Pages/MarketSimple'
import { useIntl } from 'gatsby-plugin-intl'
const IndexPage = ({ location }) => {
  const query = new URLSearchParams(location.search)
  const [queryCoinId, setQueryCoinId] = useState('default')
  const intl = useIntl()
  useEffect(() => {
    setQueryCoinId(query.get('id'))
  }, queryCoinId)
  return (
    <Layout>
      <SEO
        queryCoinId={queryCoinId}
        type="coin-view"
        title={intl.formatMessage({ id: 'titles.prices' })}
        description={intl.formatMessage({ id: 'metaDescriptions.prices' })}
      />{' '}
      <PageBody location={location} />
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object
}

export default IndexPage
